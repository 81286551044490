import { FooterSectionType } from ".";

const getExamPreparation = (): FooterSectionType => {
  return {
    titleI18Key: 'footer.exam_preparation.title',
    links: [
      {
        i18Key: 'footer.exam_preparation.toeic',
        href: '/search?exam=%E5%A4%9A%E7%9B%8A',
      },
      {
        i18Key: 'footer.exam_preparation.toefl',
        href: '/search?exam=%E6%89%98%E7%A6%8F',
      },
      {
        i18Key: 'footer.exam_preparation.ielts',
        href: '/search?exam=%E9%9B%85%E6%80%9D',
      },
      {
        i18Key: 'footer.exam_preparation.gsat',
        href: '/search?exam=%E5%AD%B8%E6%B8%AC',
      },
      {
        i18Key: 'footer.exam_preparation.jcee',
        href: '/search?exam=%E7%B5%B1%E6%B8%AC',
      },
      {
        i18Key: 'footer.exam_preparation.gept',
        href: '/search?exam=%E5%85%A8%E6%B0%91%E8%8B%B1%E6%AA%A2',
      },
    ],
  }
};

export default getExamPreparation;
