import type { FooterSectionType } from '.';

const getAboutUs = ({ faqClick, contactUsClick }: {
  faqClick: () => void; contactUsClick: () => void;
}): FooterSectionType => {
  return {
    titleI18Key: 'footer.about_us.title',
    links: [
      { i18Key: 'footer.about_us.shop', href: '/' },
      { i18Key: 'footer.about_us.faq', onClick: faqClick },
      { i18Key: 'footer.about_us.contact_us', onClick: contactUsClick },
      { i18Key: 'footer.about_us.join_us', href: 'https://www.yourator.co/companies/wordup' },
    ],
  };
};

export default getAboutUs;
