import Image from 'next/image'

const DumplingPopcornSilhouette = () => {
  return (
    <div className='absolute top-[-44px] right-[24.7px] mobileUp:right-[85px]'>
      <Image
        src='https://public.wordup.com.tw/app_assets/dumpling_popcorn_+silhouette.png'
        height={174}
        width={299}
        className='max-h-[44px] w-auto'
        alt='silhouette'
      />
    </div>
  )
}

export default DumplingPopcornSilhouette
