import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'
import { useCallback, useMemo, useState } from 'react'

import { darkModeLogoUrl } from '@/components/layouts/common/components/desktop-nav-bar/constants'
import useWuIntercom from '@/hooks/use-wu-intercom'

import AppStoreLink from '../app-download-link'
import Link from '../link'
import { Text } from '@libs-components/components/text'
import DumplingPopcornSilhouette from './components/dumpling-popcorn-silhouette'
import LinkSection from './components/link-section'

import getAboutUs from './sections/about-us'
import getProductAndService from './sections/product-and-service'
import getLanguageLearning from './sections/language-learning'
import getExamPreparation from './sections/exam-preparation'
import getPartners from './sections/partners'

const FaqModal = dynamic(import('./components/faq-modal'))

const Footer = () => {
  const { t } = useTranslation('common')
  const [showModal, setShowModal] = useState(false)
  const { openIntercomMessenger } = useWuIntercom()

  const openModal = useCallback(() => {
    setShowModal(true)
  }, [setShowModal])

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  const linkSectionsData = useMemo(
    () => [
      getAboutUs({ faqClick: openModal, contactUsClick: openIntercomMessenger }),
      getProductAndService(),
      getLanguageLearning(),
      getExamPreparation(),
      getPartners(),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, openModal, openIntercomMessenger],
  )
  const lastSectionIndex = linkSectionsData.length - 1
  const isEvenSections = linkSectionsData.length % 2 === 0

  return (
    <footer className='relative grid grid-cols-[1fr_1fr] mobileUp:grid-cols-[auto_124px_124px_124px_124px_124px] mobileUp:grid-rows-[auto_1fr]'>
      <div className='col-span-2 flex w-full flex-col gap-3 bg-grayscale-800 px-[20px] pt-[26px] pb-4 mobileUp:col-span-1 mobileUp:px-[38px] mobileUp:pt-[26px] mobileUp:pb-0'>
        <Image
          src={darkModeLogoUrl}
          alt='logo'
          width={190.625}
          height={25}
          className='h-auto max-h-[25px] max-w-full'
        />

        <Text
          variant='label_s_400'
          color='grayscale-400'
          className='text-justify mobileUp:max-w-[455px]'
        >
          {t('footer.company_description')}
        </Text>
      </div>

      {linkSectionsData.map((section, idx) => {
        const shouldTakeFullColSpan = idx === lastSectionIndex && !isEvenSections

        return (
          <div
            key={`${idx}_${section.titleI18Key}`}
            className={twMerge(
              'bg-grayscale-800',
              'border-0 border-t border-solid border-grayscale-700',
              'py-4 px-5 mobileUp:p-[26px_16px_26px_0px]',
              'mobileUp:col-span-1 mobileUp:row-span-2',
              shouldTakeFullColSpan ? 'col-span-2' : 'col-span-1',
            )}
          >
            <LinkSection section={section} />
          </div>
        )
      })}

      <div className='col-span-2 row-start-5 flex h-full items-start justify-center gap-3 bg-grayscale-900 p-[24px_24px_0px] mobileUp:col-span-1 mobileUp:row-start-2 mobileUp:justify-start mobileUp:bg-grayscale-800 mobileUp:p-[12px_38px]'>
        <AppStoreLink platform='android' />
        <AppStoreLink platform='ios' />
      </div>
      <div className='col-span-2 row-start-7 flex items-center justify-center bg-grayscale-900 px-6 pb-6 pt-0 text-center mobileUp:col-span-1 mobileUp:row-start-3 mobileUp:block mobileUp:px-[38px] mobileUp:pt-[21px] mobileUp:pb-[21px] mobileUp:text-left'>
        <Text variant='label_s_400' color='grayscale-50'>
          © 2017 - {new Date().getFullYear()} WORD UP All Rights Reserved.
        </Text>
      </div>

      <div className='col-span-2 flex flex-col items-center justify-end gap-3 bg-grayscale-900 p-[16px_24px_12px_24px] text-grayscale-50 mobileUp:col-span-5 mobileUp:flex-row mobileUp:gap-0 mobileUp:p-[21px_38px]'>
        <Link target='_self' href='https://shop.wordup.com.tw/terms-of-service'>
          <Text variant='label_s_400' color='grayscale-50'>
            {t('footer.user_terms')}
          </Text>
        </Link>
        <div className='hidden mobileUp:block'>・</div>
        <Text variant='label_s_400' color='grayscale-50'>
          聰明學習股份有限公司
        </Text>
        ・
        <Text variant='label_s_400' color='grayscale-50'>
          {t('footer.tax_number')} 82816072
        </Text>
      </div>

      {showModal && <FaqModal open={showModal} onClose={closeModal} />}

      <DumplingPopcornSilhouette />
    </footer>
  )
}

export default Footer
