import { useEffect, useMemo, useState } from 'react'
import { setShowIntercom } from '@/hooks/use-wu-intercom'

const useInit = () => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const action = useMemo(
    () => ({ onOpen: () => setIsModalOpened(true), onClose: () => setIsModalOpened(false) }),
    [],
  )

  useEffect(() => {
    setShowIntercom(!isModalOpened)
    return () => setShowIntercom(true)
  }, [isModalOpened])

  return { store: { isModalOpened }, action }
}

export default useInit
