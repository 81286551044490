import { ifAbsUrl } from '@/libs/utils/url'

// Reference: https://developers.cloudflare.com/images/image-resizing/integration-with-frameworks/

const normalizeSrc = src => {
  return src.startsWith('/') ? src.slice(1) : src
}

const cloudflareLoader = ({ src, width, quality }) => {
  const domain = ifAbsUrl(src) ? new URL(src).origin : ''
  const params = [`width=${width}`]

  if (quality) {
    params.push(`quality=${quality}`)
  }
  const paramsString = params.join(',')
  return `${domain}/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`
}

export default cloudflareLoader
