import { FooterSectionType } from ".";

const getPartners = (): FooterSectionType => {
  return {
    titleI18Key: 'footer.partners.title',
    links: [
      {
        i18Key: 'footer.partners.teacher_course_sales',
        href: '/teaching',
      },
      {
        i18Key: 'footer.partners.digital_publication_sales',
        href: 'mailto:service@wordup.com.tw',
      },
    ],
  };
};

export default getPartners;
