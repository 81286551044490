import type { FilterType } from './types'
import dynamic from 'next/dynamic'
import cookie from '@/libs/cookie'
import { isWuAppWebview } from '@libs-components/utils/device'
import postRnWebviewMessage, {
  POST_RN_WEBVIEW_MESSAGE_TYPES,
} from '@/libs/utils/post-rn-webview-message'

export const DEFAULT_FILTER: FilterType = {
  subject: { label: '', value: '' },
  catExam: { label: '', value: '', sub: { label: '', value: '' } },
  catKind: { label: '', value: [] },
}

export const RECOMMENDATION_FILTERS = 'recommendation_filters' as const
export const ACTIVE_CLASS_NAME = 'pointer-events-none border-primary-500' as const
export const DISABLED_CLASS_NAME = 'pointer-events-none bg-grayscale-50' as const
export const DEFAULT_CLASS_NAME =
  'select-none border border-solid border-grayscale-100 transition-colors cursor-pointer hover:border-primary-500 duration-200 ease-in-out break-word break-words whitespace-pre-line' as const

export const STEPS = { STEP1: 0, STEP2: 1, STEP3: 2 } as const
export const StepComponents = [
  dynamic(import('./Steps/Step1')),
  dynamic(import('./Steps/Step2')),
  dynamic(import('./Steps/Step3')),
] as const

export const setRecommendationFilters = (filters: FilterType) => {
  cookie.set({
    key: RECOMMENDATION_FILTERS,
    value: JSON.stringify(filters),
    domain: process.env.NEXT_PUBLIC_WU_SUB_DOMAIN!,
  })
  if (isWuAppWebview()) {
    postRnWebviewMessage({
      type: POST_RN_WEBVIEW_MESSAGE_TYPES.RECOMMENDATION_FILTERS,
      payload: filters,
    })
  }
}

export const getSearchParams = ({ subject, catExam, catKind }: FilterType) => {
  let searchParams: { subject: string; cat_kind: string; cat_exam?: string } = {
    subject: subject.value,
    cat_kind: catKind.value.join(','),
  }

  const exam = catExam.sub.value || catExam.value
  switch (searchParams.subject) {
    case 'english': {
      searchParams.cat_exam = exam
      break
    }
    case 'japanese':
    case 'korean': {
      if (exam !== 'daily') searchParams.cat_exam = exam
      break
    }
    default:
      break
  }

  return new URLSearchParams(searchParams)
}
